/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-28 21:53:44
 * @LastEditors: hutian
 * @LastEditTime: 2021-04-20 10:39:22
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerStatement = params => axios({
    url: '/api/order/statements/dealerStatements/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addFinanceReceipt = params => axios({
    url: '/api/dealer/dealer/dealerPaymentFrom/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {

        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editFinanceReceipt = params => axios({
    url: '/api/dealer/dealer/dealerPaymentFrom/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delFinanceReceipt = params => axios({
    url:'/api/dealer/dealer/dealerPaymentFrom/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdFinanceReceipt = params => axios({
    url: '/api/dealer/dealer/dealerPaymentFrom/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByOrderCodeFinanceReceipt = params => axios({
  url: '/api/dealer/dealer/dealerPaymentFrom/selectByOrderCode/' + params,
  method: 'get',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})


export const downExcel = params => axios({
  url: '/api/customer/customer/financeReceipt/excel',
  method: 'post',
  responseType: 'blob',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const listLog = params => axios({
  url: '/api/dealer/dealer/paymentFromTrackLog/list',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const rollbackStatus = params => axios({
  url:'/api/dealer/dealer/dealerPaymentFrom/rollbackStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const returnStatus = params => axios({
  url:'/api/dealer/dealer/dealerPaymentFrom/returnStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})



